var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { getCurrentWorkspace } from '@/entities/createWorkspace/model/createWorkspaceSlice';
var checkIfCalculatingStarted = function (blocks) {
    return Object.values(blocks).some(function (block) {
        return block.state === 'done' ||
            block.state === 'calculating' ||
            block.trigger_reason != null;
    });
};
// const initialBlocks: Record<string, RuntimeBlock> = {
//   '108': { id: '79', state: 'done' },
//   '110': { id: '66', state: 'calculating' },
//   '86': { id: '86', state: 'calculating' },
//   '67': { id: '4', state: 'idle' },
//   '87': { id: '5', state: 'idle' },
//   '68': { id: '4', state: 'idle' },
//   '88': { id: '5', state: 'idle' },
//   '72': { id: '5', state: 'idle' },
//   '89': { id: '5', state: 'idle' },
// };
var nodesTypes = {
    0: 'Filter',
    1: 'Strategiest',
    2: 'Strategiest',
    3: 'Weigher',
    4: 'Weigher',
    5: 'Deal Entry',
    6: 'Deal Exit',
    7: 'Deal Exit',
};
var initialState = {
    blocks: {},
    isCalculatingStarted: false,
};
var extractBlockTickersFromPayload = function (block, metaTickers) {
    var _a, _b, _c, _d;
    var received = {};
    var returned = {};
    var tickerData = (_a = block.last_run_ticker_data) !== null && _a !== void 0 ? _a : {};
    if (!tickerData || Object.keys(tickerData).length === 0) {
        return null;
    }
    for (var _i = 0, metaTickers_1 = metaTickers; _i < metaTickers_1.length; _i++) {
        var meta = metaTickers_1[_i];
        var result = tickerData[meta.id];
        received[meta.id] = __assign(__assign({}, meta), { received_at: (_b = result === null || result === void 0 ? void 0 : result.base_datetime) !== null && _b !== void 0 ? _b : null });
        var state = 'calculating';
        if (result === null || result === void 0 ? void 0 : result.error) {
            state = 'error';
        }
        else if ((result === null || result === void 0 ? void 0 : result.result) === true) {
            state = 'true';
        }
        else if ((result === null || result === void 0 ? void 0 : result.result) === false) {
            state = 'false';
        }
        returned[meta.id] = __assign(__assign({}, meta), { state: state, error: (_c = result === null || result === void 0 ? void 0 : result.error) !== null && _c !== void 0 ? _c : null, returned_at: (_d = result === null || result === void 0 ? void 0 : result.base_datetime) !== null && _d !== void 0 ? _d : null });
    }
    return { received: received, returned: returned };
};
var runtimeSlice = createSlice({
    name: 'runtime',
    initialState: initialState,
    reducers: {
        setBlockState: function (state, action) {
            var _a = action.payload, id = _a.id, newState = _a.state;
            if (!state.blocks[id]) {
                state.blocks[id] = { id: id, state: 'idle' };
            }
            state.blocks[id].state = newState;
            state.isCalculatingStarted = checkIfCalculatingStarted(state.blocks);
        },
        setTriggerReason: function (state, action) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            var _a = action.payload, id = _a.id, trigger_reason = _a.trigger_reason;
            if (!state.blocks[id]) {
                state.blocks[id] = { id: id, state: 'idle' };
            }
            state.blocks[id].trigger_reason = trigger_reason;
            state.isCalculatingStarted = checkIfCalculatingStarted(state.blocks);
        },
        updateBlockFromSocket: function (state, action) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            var msg = action.payload;
            var blockId = String(msg.block.id);
            if (!state.blocks[blockId]) {
                state.blocks[blockId] = {
                    id: blockId,
                    state: 'idle',
                    name: msg.block.name,
                    tickers: {
                        received: {},
                        returned: {},
                    },
                };
            }
            if (msg.trigger_reason && msg.trigger_reason !== 'Activation') {
                state.blocks[blockId].trigger_reason = {
                    trigger: msg.trigger_reason,
                    ticker: ((_a = msg.ticker) === null || _a === void 0 ? void 0 : _a.name) || '',
                };
            }
            switch (msg.action) {
                // }
                case 'cancel run - already running': {
                    state.blocks[blockId].state = 'idle';
                    state.blocks[blockId].trigger_reason = {
                        trigger: '',
                        ticker: '',
                    };
                    break;
                }
                case 'start calculation': {
                    if (!state.blocks[blockId].tickers) {
                        state.blocks[blockId].tickers = { received: {}, returned: {} };
                    }
                    var tickerId = (_b = msg.ticker) === null || _b === void 0 ? void 0 : _b.id;
                    if (tickerId) {
                        state.blocks[blockId].tickers.received[tickerId] = {
                            id: tickerId,
                            name: ((_c = msg.ticker) === null || _c === void 0 ? void 0 : _c.name) || '',
                            logo_url: ((_d = msg.ticker) === null || _d === void 0 ? void 0 : _d.logo_url) || '',
                            full_name: ((_e = msg.ticker) === null || _e === void 0 ? void 0 : _e.full_name) || '',
                            received_at: new Date().toISOString(),
                        };
                        state.blocks[blockId].tickers.returned[tickerId] = {
                            id: tickerId,
                            name: ((_f = msg.ticker) === null || _f === void 0 ? void 0 : _f.name) || '',
                            logo_url: ((_g = msg.ticker) === null || _g === void 0 ? void 0 : _g.logo_url) || '',
                            full_name: ((_h = msg.ticker) === null || _h === void 0 ? void 0 : _h.full_name) || '',
                            state: 'calculating',
                            error: null,
                            returned_at: null,
                        };
                    }
                    state.blocks[blockId].state = 'calculating';
                    break;
                }
                case 'calculation complete': {
                    state.blocks[blockId].state = 'done';
                    var tickerId = (_j = msg.ticker) === null || _j === void 0 ? void 0 : _j.id;
                    if (tickerId && ((_k = state.blocks[blockId].tickers) === null || _k === void 0 ? void 0 : _k.returned[tickerId])) {
                        var tickerToUpdate = state.blocks[blockId].tickers.returned[tickerId];
                        var newState = 'calculating';
                        if (msg.result === true) {
                            newState = 'true';
                        }
                        else if (msg.result === false) {
                            newState = 'false';
                        }
                        tickerToUpdate.state = newState;
                        tickerToUpdate.returned_at = new Date().toISOString();
                    }
                    var returnedTickers = Object.values((_m = (_l = state.blocks[blockId].tickers) === null || _l === void 0 ? void 0 : _l.returned) !== null && _m !== void 0 ? _m : {});
                    if (returnedTickers.length > 0 &&
                        returnedTickers.every(function (t) { return t.state !== 'calculating'; })) {
                        state.blocks[blockId].state = 'done';
                    }
                    break;
                }
                case 'calculation is done': {
                    state.blocks[blockId].state = 'done';
                    break;
                }
                default:
                    break;
            }
            state.isCalculatingStarted = checkIfCalculatingStarted(state.blocks);
        },
    },
    extraReducers: function (builder) {
        builder.addCase(getCurrentWorkspace.fulfilled, function (state, action) {
            var _a;
            state.blocks = {};
            var allTickers = (_a = action.payload.last_run_data) === null || _a === void 0 ? void 0 : _a.tickers;
            action.payload.blocks.forEach(function (block) {
                var newState = 'idle';
                var blockId = String(block.id);
                var tickerData = block.last_run_ticker_data;
                if (tickerData) {
                    if (tickerData && Object.keys(tickerData).length > 0) {
                        var results = Object.values(tickerData).map(function (i) { return i ? i.result : null; });
                        // const brokerError = Object.values(tickerData).map((i: any) => i.broker_error);
                        if (action.payload.last_run_data.state === 'calculation is done') {
                            newState = 'idle';
                        }
                        else {
                            if (results.some(function (r) { return r === null; })) {
                                newState = 'calculating';
                            }
                            else {
                                newState = 'done';
                            }
                        }
                    }
                    else {
                        newState = 'idle';
                    }
                    var tickers = extractBlockTickersFromPayload(block, allTickers);
                    if (action.payload.last_run_data.state === 'calculation is done') {
                        if (tickerData &&
                            Object.values(tickerData).length > 0 &&
                            Object.values(tickerData).every(function (obj) {
                                return obj.result === null &&
                                    obj.base_datetime === null &&
                                    obj.error === null &&
                                    obj.broker_error === null;
                            })) {
                            tickers = null;
                        }
                    }
                    state.blocks[blockId] = {
                        id: blockId,
                        name: block.name,
                        blockType: nodesTypes[block.strategy_step_type],
                        state: newState,
                        tickers: tickers,
                    };
                }
            });
            state.isCalculatingStarted = checkIfCalculatingStarted(state.blocks);
        });
    },
});
export var setBlockState = (_a = runtimeSlice.actions, _a.setBlockState), setTriggerReason = _a.setTriggerReason, updateBlockFromSocket = _a.updateBlockFromSocket;
export default runtimeSlice.reducer;
