var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isMenuOpen: false,
    widgets: [],
    bounds: null,
};
var widgetMenuSlice = createSlice({
    name: 'widgetMenuSlice',
    initialState: initialState,
    reducers: {
        toggleMenu: function (state) {
            state.isMenuOpen = !state.isMenuOpen;
        },
        setMenuState: function (state, action) {
            state.isMenuOpen = action.payload;
        },
        setWidgets: function (state, action) {
            state.widgets = action.payload;
        },
        setWorkspaceBounds: function (state, action) {
            state.bounds = action.payload;
        },
    },
});
export var toggleMenu = (_a = widgetMenuSlice.actions, _a.toggleMenu), setMenuState = _a.setMenuState, setWidgets = _a.setWidgets, setWorkspaceBounds = _a.setWorkspaceBounds;
export default widgetMenuSlice.reducer;
